<template>
    <v-overlay :value="isOverlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>
<script>
export default {

    data: () => ({
		overlay:false,
	}),
	computed: {
		isOverlay(){
			return this.$store.state.overlayFlg;
		}
	},
	methods:{
		show(){
			this.overlay = true;
		},
		hide(){
			this.overlay = false;
		}
	}
}
</script>