export class CONSTANT {

	/********************
     * API戻り値
     ********************/
    /**  正常 */
    static RETURN_NORMAL = "0";
    /** データなし */
    static RETURN_NOT_FOUND= "1";
    /** DBワーニング */
    static RETURN_DB_WARNING= "2";
    /** 致命的エラー */
    static RETURN_INTERNAL_ERROR= "9";


    /***************** 
      画面モード 
     *****************/
    /** 追加 */
    static MODE_ADD = "A";
    /** 照会 */
    static MODE_VIEW = "V";
    /** 更新 */
    static MODE_UPDATE = "U";
    /** 削除 */
    static MODE_DELETE = "E";



}