import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //ログイン成功時のトークン保存
    token:'',

    //メニューの表示・非表示を保存 true:表示
    showMenu:true,

    //会員ID
    memberId:'',

    //会員区分
    memberKbn:'',

    //得意先
    tokuisakiList:[],

    //現場
    genbaList:[],

    //コンテナ
    containerList:[],

    //コンテナタイプ
    containerTypeList:[],

    // プラント照会
    plantInqId:'',

    //オーバーレイフラグ
    overlayFlg:false,

    //[戻る]ボタン押下時の画面のパス
    returnPath:'',

    //お知らせ一覧
    informations:[],

  },
  mutations: {
    //メニューの表示・非表示を切り替える
    chgShowMenu(state){
      state.showMenu = !state.showMenu ;
    },
    setShowMenu(state, payload){
      state.showMenu = payload.flg; 
    },
    setToken(state,token){
      state.token = token;
    },

    //会員ID
    setMemberId(state,memberId){
      state.memberId = memberId;
    },
    //会員区分 1:正規　2:賛助会員
    setMemberKbn(state,memberKbn){
      state.memberKbn = memberKbn;
    },

    //プラント照会ID
    setPlantInqId(state,plantInqId)
    {
      state.plantInqId = plantInqId;
    },
    setTokuisakiList(state, tokuisakiList)
    {
      state.tokuisakiList = tokuisakiList;
    },
    setGenbaList(state, genbaList)
    {
      state.genbaList = genbaList;
    },
    setContainerList(state, containerList)
    {
      state.containerList = containerList;
    },
    setContainerTypeList(state, containerTypeList)
    {
      state.containerTypeList = containerTypeList;
    },
    showOverlay(state)
    {
      state.overlayFlg = true;
    },
    hideOverlay(state)
    {
      state.overlayFlg = false;
    },
    setReturnPath(state, path)
    {
      state.returnPath = path;
    },
    setInformations(state, informations)
    {
      state.informations = informations; 
    }

  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: 'vuex-session',
      storage: window.sessionStorage
    })
  ]
})
