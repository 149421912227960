<template>
  <v-app>
    <Menu />
    <Header/>
    <v-main>
      <router-view />
    </v-main>
    <Footer />
    <Overlay ref="overlay" />

  </v-app>
</template>
<script>
import Menu from "./components/Menu"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Overlay from './views/Overlay.vue';
export default {
  components:{Menu,Header, Footer , Overlay},
  computed:{
  }
};
</script>
