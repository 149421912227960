var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pa-0",attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4",attrs:{"elevation":"2","height":"40"}},[_c('v-icon',{staticClass:"white--text mb-2"},[_vm._v("mdi-database")]),_vm._v(" プラント管理 ")],1),_c('v-card',{staticClass:"ma-5"},[_c('v-system-bar',{attrs:{"color":"gray","height":"50"}},[_c('v-card-subtitle',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" プラント一覧 ")]),_c('v-spacer')],1)],1),_c('v-card',{staticClass:"ma-5"},[_c('v-data-table',{staticClass:"plant-list",attrs:{"height":"600","headers":_vm.headers,"items":_vm.plantList,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.now_gendo_uke",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.now_gendo_uke))),_c('span',{staticClass:"text-caption pl-1"},[_vm._v("m3")])]}},{key:"item.now_kaido_stock",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.now_kaido_stock))),_c('span',{staticClass:"text-caption pl-1"},[_vm._v("m3")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-edit",attrs:{"small":""},on:{"click":function($event){return _vm.clickView(item)}}},[_vm._v("プラント詳細へ")])]}},{key:"item.limit_date",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[item.count_date>0?'over':'']},[_vm._v(_vm._s(item.limit_date))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }