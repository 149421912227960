import Vue from 'vue'
import VueRouter from 'vue-router'
import MemberInqury from '../views/MemberInqury.vue'
import MemberEdit from '../views/MemberEdit.vue'
import PlantView from '../views/PlantView.vue'
import PlantEdit from '../views/PlantEdit.vue'
import PlantList from '../views/PlantList.vue'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import store from '../store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login 
  },
  /* ダッシュボード */
  {
    /*プラント表示 */
    path: '/',
    name: 'Top',
    component: Dashboard 
  },
  {
    /*プラント表示 */
    path: '/plant-edit',
    name: 'PlantEdit',
    component: PlantEdit 
  },
  {
    /*プラント表示 */
    path: '/plant',
    name: 'PlantList',
    component: PlantList 
  },
  {
    /*プラント照会 */
    path: '/plant-view',
    name: 'PlantView',
    component: PlantView 
  },
  {
    path: '/member-inqury',
    name: 'MemberInqury',
    component: MemberInqury
  },
  {
    path: '/member-edit',
    name: 'MemberEdit',
    component: MemberEdit
  },
  {path: '/maintenance', name:'maintenance', component:()=>import('../views/Maintenance.vue')},
  {path: '/member-view', name:'memberview', component:()=>import('../views/MemberView.vue')},
  {path: '/password', name:'password', component:()=>import('../views/Password.vue')},
  {path: '/timeout', name:'Timeout', component:()=>import('../views/Timeout.vue')},
  {path: '/plant-new', name:'PlantNew', component:()=>import('../views/PlantNew.vue')},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from , next)=>{
  if(to.name ==='Login' && store.state.token != ''){
    next({name: 'MemberList'})
  }else if(to.name ==='Timeout'){
      next();
  }else{
    if((to.name !== 'Login' && to.name!=='maintenance'  )&& store.state.token == '') next({name:'Login'})
    else next()

  }
})

export default router
