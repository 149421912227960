<template>
                <v-simple-table class="resizable-column" :class="tableclass">
				<slot></slot>
				</v-simple-table>
</template>

<script>
export default {
    props: {
		headers:{
			type:Object|Array,
			default:{}
		},
		items:{
			type:Object|Array,
			default:{}
		},
		tableclass:{
			type:String,
			defaule:''
		}
	},
    data() {
        return {
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.getResizableTable();
        });
    },
    methods: {
        // このページのテーブルというテーブル全てリサイズ可にする
        getResizableTable() {
			var aa = document.querySelector(`div.${this.tableclass} table`);
                this.resizableGrid(aa);
            // var row = aa.getElementsByTagName("tr")[0],
            //     cols = row ? row.children : undefined;
			// console.log(row, cols)
			// var aa = document.querySelector('div.resizable-column');
			// for(var i = 0; aa.length ; i++){
			// 	console.log(aa[i])
			// }

            // var tables = document.getElementsByTagName("table");
            // for (var i = 0; i < tables.length; i++) {
            //     this.resizableGrid(tables[i]);
            // }
        },

        // テーブルごとにリサイズ用の設定を追加
        resizableGrid(table) {
            var row = table.getElementsByTagName("tr")[0],
                cols = row ? row.children : undefined;
            if (!cols) return;

            table.style.overflow = "hidden";

            // ヘッダ部分だけドラッグ可
            var tableHeight = row.offsetHeight;

            // テーブルのカラムとカラムの間にドラッグできるdivを挿入
            for (var i = 0; i < cols.length - 1; i++) {
                var div = createDiv(tableHeight);
                cols[i].appendChild(div);
                cols[i].style.position = "relative";
                setListeners(div);
            }

            // 挿入したdivとdocument全体にイベントリスナーを付与
            function setListeners(div) {
                var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

                /* <div> */
                // マウス押下時
                div.addEventListener("mousedown", (e) => {
                    curCol = e.target.parentElement;
                    nxtCol = curCol.nextElementSibling;
                    pageX = e.pageX;

                    var padding = paddingDiff(curCol);

                    curColWidth = curCol.offsetWidth - padding;
                    if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
                });

                // マウスホバー時
                div.addEventListener("mouseover", (e) => {
                    e.target.style.borderRight = "2px solid #0000ff";
                });

                // マウスホバー解除時
                div.addEventListener("mouseout", (e) => {
                    e.target.style.borderRight = "";
                });

                // バブリング抑止
                div.addEventListener("click", (e) => {
                    e.stopPropagation();
                });

                /* document */
                // マウスカーソル移動時
                document.addEventListener("mousemove", (e) => {
                    if (curCol) {
                        var diffX = e.pageX - pageX;

                        if (nxtCol)
                            nxtCol.style.width = nxtColWidth - diffX + "px";

                        curCol.style.width = curColWidth + diffX + "px";
                    }
                });

                // マウス押下解除時
                document.addEventListener("mouseup", (e) => {
                    curCol = undefined;
                    nxtCol = undefined;
                    pageX = undefined;
                    nxtColWidth = undefined;
                    curColWidth = undefined;
                });
            }

            // リサイズ用divの実態を作成
            function createDiv(height) {
                var div = document.createElement("div");
                div.style.top = 0;
                div.style.right = 0;
                div.style.width = "5px";
                div.style.position = "absolute";
                div.style.cursor = "col-resize";
                div.style.userSelect = "none";
                div.style.height = height + "px";
                return div;
            }

            // この辺はよくわからん
            function paddingDiff(col) {
                if (getStyleVal(col, "box-sizing") == "border-box") {
                    return 0;
                }

                var padLeft = getStyleVal(col, "padding-left");
                var padRight = getStyleVal(col, "padding-right");
                return parseInt(padLeft) + parseInt(padRight);
            }

            function getStyleVal(elm, css) {
                return window.getComputedStyle(elm, null).getPropertyValue(css);
            }
        },
    },
};
</script>

<style scoped>
/* ヘッダに縦線出すよ */
.resizable-column /deep/ th + th {
    border-left: 1px solid grey;
}

/* ヘッダ名が折り曲がらないようにする */
.resizable-column /deep/ th {
    white-space: nowrap;
}
</style>
