<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-account</v-icon>
             会員管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    会員登録
                </v-card-subtitle>
                <v-spacer />

                    <v-btn large class="ma-2 btn-edit" @click="clickSave">保存</v-btn>
                    <v-btn large class="ma-2 btn-close" @click="clickBack">戻る</v-btn>
            </v-system-bar>
        </v-card>
        <v-card class="ma-5">
            <div class="pa-5">
                <table class="member-edit-table">
                    <colgroup>
                        <col width="180" />
                        <col width="150" />
                        <col width="150" />
                        <col width="200" />
                        <col width="150" />
                        <col width="200" />
                    </colgroup>
                    <tr>
                        <th class="text-left pl-3">会員（会社）名称<span class="required">(必須)</span></th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                maxlength="40"
                                :error="isError(err.memberName)"
                                outlined
                                hide-details=""
                                dense
                                class="tokui-name"
                                v-model="memberInfo.member_name"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.memberName)"
                            >
                                <span v-html="dispError(err.memberName)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">会員（会社）カナ<span class="required">(必須)</span></th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                maxlength="40"
                                :error="isError(err.memberNameKana)"
                                outlined
                                hide-details=""
                                dense
                                class="tokui-kana"
                                v-model="memberInfo.member_name_kana"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.memberNameKana)"
                            >
                                <span v-html="dispError(err.memberNameKana)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">代表者名<span class="required">(必須)</span></th>
                        <td colspan="2">
                            <v-text-field
                                solo
                                maxlength="20"
                                outlined
                                :error="isError(err.daihyoName)"
                                hide-details=""
                                dense
                                class="tantou-name"
                                v-model="memberInfo.daihyo_name"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.daihyoName)"
                            >
                                <span v-html="dispError(err.daihyoName)"></span>
                            </div>
                        </td>
                        <th class="text-left pl-3">会員法人番号</th>
                        <td colspan="2">
                            <v-text-field
                                v-model="memberInfo.member_no"
                                type="tel"
                                solo
                                maxlength="13"
                                outlined
                                hide-details=""
                                dense
                                class="width-200"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">郵便番号<span class="required">(必須)</span></th>
                        <td colspan="3">
                            <div class="d-inline-flex align-center">
                                <v-text-field
                                    solo
                                    type="tel"
                                    maxlength="8"
                                    outlined
                                    hide-details="auto"
                                    :error="isError(err.post)"
                                    dense
                                    class="post"
                                    v-model="memberInfo.post"
                                    @blur="blurPost"
                                />
                                <v-btn
                                    class="my-2 ml-8"
                                    color="primary"
                                    @click="getAddress"
                                    >郵便番号から住所に変換</v-btn
                                >
                            </div>
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.post)"
                            >
                                <span v-html="dispError(err.post)"></span>
                            </div>
                        </td>
                        <th class="text-left pl-3">市区町村CD<span class="required">(必須)</span></th>
                        <td>
                            <v-text-field
                                solo
                                type="number"
                                maxlength="6"
                                outlined
                                :error="isError(err.areaCd)"
                                hide-details=""
                                dense
                                class="width-200"
                                v-model="memberInfo.area_cd"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.areaCd)"
                            >
                                <span v-html="dispError(err.areaCd)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3" rowspan="3">住所</th>
                        <th>都道府県<span class="required">(必須)</span></th>
                        <td colspan="4">
                            <v-select
                                v-model="memberInfo.tdfk_name"
                                class="width-200"
                                height="32"
                                outlined
                                :error="isError(err.tdfkName)"
                                solo
                                dense
                                hide-details=""
                                :items="tdfklist"
                                item-text="tdfk_name"
                                item-value="tdfk_name"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.tdfkName)"
                            >
                                <span v-html="dispError(err.tdfkName)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>市区町村<span class="required">(必須)</span></th>
                        <td colspan="4">
                            <v-text-field
                                solo
                                maxlength="40"
                                outlined
                                :error="isError(err.address1)"
                                hide-details=""
                                dense
                                class="address"
                                v-model="memberInfo.address1"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.address1)"
                            >
                                <span v-html="dispError(err.address1)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>丁目番地</th>
                        <td colspan="4">
                            <v-text-field
                                solo
                                maxlength="40"
                                outlined
                                hide-details=""
                                dense
                                class="address"
                                v-model="memberInfo.address2"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">電話番号／FAX番号</th>
                        <td colspan="5">
                            <div class="d-inline-flex align-center">
                                <span class="mr-2">電話番号</span>
                                <v-text-field
                                    solo
                                    type="tel"
                                    maxlength="14"
                                    :error="isError(err.tel)"
                                    outlined
                                    hide-details=""
                                    dense
                                    class="tel"
                                    v-model="memberInfo.tel"
                                />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.tel)"
                                >
                                    <span v-html="dispError(err.tel)"></span>
                                </div>
                                <span class="ml-4 mr-2">FAX番号</span>
                                <v-text-field
                                    solo
                                    type="tel"
                                    maxlength="14"
                                    :error="isError(err.fax)"
                                    outlined
                                    hide-details=""
                                    dense
                                    class="tel"
                                    v-model="memberInfo.fax"
                                />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.fax)"
                                >
                                    <span v-html="dispError(err.fax)"></span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">担当者名</th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                maxlength="40"
                                outlined
                                hide-details=""
                                dense
                                class="tantou-name"
                                v-model="memberInfo.tantou_name"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">メールアドレス<span class="required">(必須)</span></th>
                        <td colspan="5">
                            <v-text-field
                                solo
                                maxlength="60"
                                type="tel"
                                :error="isError(err.mail)"
                                outlined
                                hide-details=""
                                dense
                                class="mail"
                                v-model="memberInfo.mail"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.mail)"
                            >
                                <span v-html="dispError(err.mail)"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left pl-3">ホームページ</th>
                        <td colspan="5">
                            <v-text-field
                                type="tel"
                                solo
                                maxlength="40"
                                outlined
                                hide-details=""
                                dense
                                class="mail"
                                v-model="memberInfo.homepage"
                            />
                        </td>
                    </tr>
                </table>
                <div class="text-left">
                </div>
            </div>
        </v-card>
    </v-container>
</template>
<style lang="scss" scoped>
.member-edit-table {
    table-layout: fixed;
    width: 950px;
    border: 1px solid #ccc;

    th {
        border: 1px solid #ccc;
        text-align: left;
        padding-left: 5px;
        background-color: #555;
        color: white;
        font-weight: normal;
        padding: 5px 5px 5px 5px;
    }
	td {
		border: 1px solid #ccc;
        padding: 5px 5px 5px 5px;
	}
    td .dspfld {
        height: 34px;
        margin: 5px 0;
        border: 1px solid #aaa;
        background-color: #eee;
        font-size: 16px;
    }
	.v-input{
		margin-top: 0 !important;
		margin-bottom: 0 !important;
        padding-top: 6px;
	}
    .width-200{
        width: 200px;
    }
}
</style>
<script>
import { MESSAGE } from "../messages";
import InputDateFld from "../components/InputDateFld";
import { CONSTANT } from '../constant';

export default {
    components: { InputDateFld },
    data() {
        return {
            mode: "i", //i:新規 u:更新
            saveLoading: false,
            memberId:'',

            //入力v-model
            memberInfo:[],
            //エラー表示用
            err: ({} = {
                status:[],
				nyukaiBi:[],
				taikaiBi: [],
				memberKbn: [],
				shubetsuKbn: [],
				shibuCd: [],
                memberName:[],
                memberNameKana:[],
                daihyoName:[],
				memberNo: [],
                post:[],
                areaCd:[],
				tdfkName: [],
                address1:[],
                address2:[],
                tel:[],
                fax:[],
				tantouName: [],
                mail:[],
				homepage: [],
                memo:[],
                password:'',
            }),
            //プルダウンリスト
            satuslist:[
                { val: 0, name: "通常" },
                { val: 1, name: "停止" },
                { val: 2, name: "保留" },
                { val: 9, name: "退会" },
            ],
            kbnlist:[
                { val: 1, name: "正会員" },
                { val: 2, name: "賛助会員" },
            ],
            shubetsulist:[
                { val: 0, name: "リサイクル業者" },
                // { val: 1, name: "工事事業者" },
                // { val: 2, name: "その他事業者" },
            ],
            shibulist:[],
            tdfklist:[],

        };
    },
    mounted(){
        this.memberId = this.$route.params.memberId;
        //todo 会員IDが取得できなかった場合

        this.getMember();

        this.getLists();
    },
    computed:{
    },
    methods:{
        async getLists(){
            this.setOverlay(true);
            const res = await this.axios.get("/member/member-get-list");
            this.setOverlay(false);
            if(res.data.resultCode == CONSTANT.RETURN_NORMAL){
                this.shibulist = res.data.resultData.shibulist;
                this.tdfklist = res.data.resultData.tdfklist;
            }
        },

        /**
         * 会員情報取得
         */
        async getMember(){
            this.setOverlay(true);
            this.memberInfo = [];
            const res = await this.axios.post('/member/get-member');
            this.setOverlay(false);
            if(res.data.resultCode == CONSTANT.RETURN_NORMAL){
                this.memberInfo = res.data.resultData.memberInfo;
            }
        },
        /**
         * 郵便番号庵フォーカス
         * ハイフンを付与する
         */
        blurPost() {
            if (this.isPostCode(this.memberInfo.post)) {
                if (this.memberInfo.post.length === 7) {
                    this.memberInfo.post =
                        this.memberInfo.post.slice(0, 3) + "-" + this.memberInfo.post.slice(3);
                }
            }
        },
        /**
         * 郵便番号
         */
        getAddress(){
            //エラーのクリア
            this.err.post = [];
            if(this.memberInfo.post===''){
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E001, "郵便番号")];
                return;
            }

            if( !this.isPostCode(this.memberInfo.post)){
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E005, "郵便番号の形式")];
                return ;
            }
            this.axios.post(`post-address`, { post: this.memberInfo.post }).then((res) => {
                    if (res.data.resultCode == CONSTANT.RETURN_NORMAL) {
                        this.memberInfo.tdfk_name = res.data.resultData.zipinfo.tdfk_name;
                        this.memberInfo.address1 = res.data.resultData.zipinfo.address1;
                        this.memberInfo.area_cd = res.data.resultData.zipinfo.city_code;
                    }else{
                        this.err.post = [MESSAGE.formatByArr(MESSAGE.E009, "郵便番号","住所")];
                    }
            });
        },
        /**
         * 入力チェック
         */
        async inputCheck(){


            // -- 会員（会社）名称
            if (!this.memberInfo.member_name) {
                this.err.memberName = [MESSAGE.formatByArr(MESSAGE.E001,'会員（会社）名称')];
            }
            // -- 会員（会社）カナ
            if (!this.memberInfo.member_name_kana) {
                this.err.memberNameKana = [MESSAGE.formatByArr(MESSAGE.E001,'会員（会社）カナ')];
            } 
            // -- 代表者名
            /*
            if (!this.inp.daihyoName) {
                this.err.daihyoName = [MESSAGE.formatByArr(MESSAGE.E001,'代表者名')];
            }
            */ 

            // -- 郵便番号
            if (!this.memberInfo.post) {
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E001,'郵便番号')];
            }else{
                if (!this.isPostCode(this.memberInfo.post)) {
                    this.err.post = [MESSAGE.formatByArr(MESSAGE.E005,'郵便番号')];
                }
            }
            // -- 市区町村CD   
            if (!this.memberInfo.area_cd) {
                this.err.areaCd = [MESSAGE.formatByArr(MESSAGE.E001,'市区町村CD')];
            } 
            // -- 都道府県
            if (!this.memberInfo.tdfk_name) {
                this.err.tdfkName = [MESSAGE.formatByArr(MESSAGE.E001,'都道府県')];
            } 
            // -- 市区町村
            if (!this.memberInfo.address1) {
                this.err.address1 = [MESSAGE.formatByArr(MESSAGE.E001,'市区町村')];
            } 

            //*** 電話番号
            if (this.memberInfo.tel && !this.isTelNo(this.memberInfo.tel)) {
                this.err.tel = [MESSAGE.formatByArr(MESSAGE.E005, "電話番号")];
            }
            //*** FAX番号
            if (this.memberInfo.fax !== "" && !this.isTelNo(this.memberInfo.fax)) {
                this.err.fax = [MESSAGE.formatByArr(MESSAGE.E005, "FAX番号")];
            }
            //*** メールアドレス
            if (!this.memberInfo.mail) {
                this.err.mail = [MESSAGE.formatByArr(MESSAGE.E001,'メールアドレス')];
            }else{
                if (this.memberInfo.mail !== "" && !this.isMailaddress(this.memberInfo.mail)) {
                    this.err.mail = [MESSAGE.formatByArr(MESSAGE.E005, "メールドレスの形式")];
                }
                if(await this.isMail()){
                    this.err.mail = [MESSAGE.formatByArr(MESSAGE.E010, "このメールドレス")];
                }
            }

            for (let key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
        },
        async isMail(){
            let mailData = {member_id: this.memberId, mail: this.memberInfo.mail};
            return await this.axios.post(`/member/member-is-mail`, mailData).then((res) => {
                if (res.data.result == 1) {
                    return true;
                }
                return false;
            });
        },
        /**
         * 保存ボタンクリック
         */
        async clickSave(){

            //error clear
            this.clearError();

            // input check
            if(await this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }
            // 確認メッセージ
            const res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                (this.mode=='i')?MESSAGE.R001:MESSAGE.R002);
            if(!res){
                this.saveLoading = null;
                return ;
            }
            this.setOverlay(true);
            const upRes = await this.axios.post(`/member/member-update`,this.memberInfo);
            this.setOverlay(false);
            if(upRes.data.resultCode == CONSTANT.RETURN_NORMAL){
                this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,this.memberInfo.memberCd));
                this.$router.push({name: "memberview"});
            }

        },
        dlgCallBack(){

        },
        async clickBack(){
            this.$router.go(-1);
        },
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
    }
}
</script>