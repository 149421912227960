<template>
    <v-container fluid class="mt-0 pa-0">

       <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-home</v-icon>
            ダッシュボード  
        </v-sheet>
        <v-card class="mx-5 mb-5">
            <v-card-title>おしらせ</v-card-title>
            <v-card-text>
                <template v-if="information.length>0">
                    <v-expansion-panels>
                        <v-expansion-panel
                        v-for="(item, index) in information" :key="index">
                        <v-expansion-panel-header>
                            <span class="pr-5" style="max-width:100px">{{item.display_start}}</span>
                            <span class="d-flex justify-start">{{item.title}}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="info-message" v-html="item.content"></div>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
          
                </template>
                <template v-if="information.length==0">
                    <div>お知らせはありません</div>
                </template>

            </v-card-text>
        </v-card>
<template v-if="this.$store.state.memberKbn=='1'">
        <v-card class="mx-5">
            <v-card-title>更新期限が間近のプラント情報</v-card-title>
            <v-card-text>
                <template v-if="plantList.length==0">
                <div>更新期限が間近のプラント情報はありません</div>
                </template>
                <template v-if="plantList.length>0">
                    <v-simple-table style="width:800px">
                        <colgroup>
                        <col style="width:100px">
                        <col style="width:300px">
                        </colgroup>
                        <tr>
                            <th>プラントNo</th>
                            <th>プラント名</th>
                            <th>最終更新日</th>
                            <th></th>
                        </tr>
                        <tr v-for="(item, index) in plantList" :key="index">
                            <td style="text-align:center">{{item.plant_id}}</td>
                            <td>{{item.plant_name}}</td>
                            <td>{{item.last_upddate}}</td>
                            <td class="text-center">
                                <v-btn class="btn-edit my-2"  color="#ccc" @click="clickView(item)">プラント詳細へ</v-btn>
                            </td>
                        </tr>
                    </v-simple-table>
                </template>
            </v-card-text>
        </v-card>
</template>
        <v-card class="mx-5 mt-5">
            <v-card-title>お知らせや資料のダウンロード</v-card-title>
            <v-card-text>
                <siryo-download></siryo-download>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<style lang="scss" scoped>
.info-message{
    white-space:break-spaces;
}
.box-parts {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    .box-parts-title {
        font-size: 13pt;
        font-weight: 800;
    }
}
</style>
<script>
import { MESSAGE } from "../messages";
import moment from "moment";
import SiryoDownload from "../components/SiryoDownload.vue";
export default {
    name: "Member",
    components: {SiryoDownload},
    data() {
        return {
            kadoCnt:0,
            newCnt:0,
            systemInfo:{},
            statusFlg: null,
            information:[],
            plantList:[],
            infoList:[],
            //資料関連
            categorys:[],
            orgSiryos:[],
            siryos:[],
        };
    },
    mounted() {
        this.getDashbord();

    },
    methods: {
        /**
         * お知らせ一覧を取得
         */
        getDashbord() {
            this.information = [];
            this.plantList = [];
            this.axios.post("/member/get-information-list").then((res) => {
                if(res.data.resultCode==0){
                    this.information = res.data.resultData.informationList;
                //    let infoReads = this.$cookies.get('infoReads');
                    

                }
            });

            this.axios.post("/member/get-plant-limit-list").then((res)=>{
                if(res.data.resultCode==0){
                    this.plantList = res.data.resultData.plant_list
                }
            })
        },
        clickView(plantInf){
            this.$store.commit('setPlantInqId', plantInf.plant_id);
            this.$store.commit('setReturnPath', "/");
            this.$router.push("/plant-view");
        },
    },
};
</script>
