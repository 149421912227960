<template>
  <v-card class="mx-auto">
    <v-navigation-drawer
      app
      v-model="isMenu"
      clipped
      color="blue lighten-5"
      v-if="isLogin"
    >
      <v-container class="pl-0 pr-0">
        <v-list dense nav>

          <v-list-item-group v-model="selectedMenu" color="orange">
            <v-list-item
              to="/"
            >
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ダッシュボード
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

<template v-if="this.$store.state.memberKbn=='1'">
            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense >
              <v-toolbar-title class="text-subtitle-1"
                >プラント管理</v-toolbar-title
              >
            </v-toolbar>
            <v-list-item
              v-for="nav_list in nav_plant_lists"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="nav_list.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
</template>

            <v-toolbar elevation="2" color="blue lighten-3 mb-2" dense>
              <v-toolbar-title class="text-subtitle-1"
                >会員情報</v-toolbar-title
              >
            </v-toolbar>
            <v-list-item
              v-for="nav_list in nav_member_lists"
              :key="nav_list.no"
              :to="nav_list.link"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="nav_list.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>







          </v-list-item-group>
        </v-list>
      </v-container>
    </v-navigation-drawer>
  </v-card>
</template>
<style scope>
.menu-title {
    background-color: red im !important;
}
</style>
<script>
export default {
    data: () => ({
        drawer: null,
        selectedMenu: 1,
        nav_member_lists: [
            {
                name: "会員情報",
                icon: "mdi-account",
                link: "/member-view",
                no: 1,
            },
            {
                name: "パスワード変更",
                icon: "mdi-lock",
                link: "/password",
                no: 2,
            },
        ],
        nav_plant_lists:[
            {
                name: "プラント一覧",
                icon: "mdi-database",
                link: "/plant",
                no: 3,
            },
            {
                name: "プラント登録",
                icon: "mdi-database-plus",
                link: "/plant-new",
                no: 4,
            },
        ],
        nav_mente_lists:[
        ],

    }),
    computed: {
        isLogin() {
            return this.$store.state.token == "" ? false : true;
        },
        isMenu: {
            get(){
                return this.$store.state.showMenu;
            },
            set(value){
                this.$store.commit('setShowMenu',{flg:value})
            }
        }
    },
    mounted(){
      console.log("menu_mouted")
      //this.get_information();
    },
    methods: {
        click_item() {},
       // get_information(){
       //     this.axios.post("/member/get-information-list").then((res) => {
       //         if(res.data.resultCode==0){
       //             let info = res.data.resultData.informationList;
       //             console.log(info)
       //         }
       //     });
       // }
    },
};
</script>
