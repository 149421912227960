import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import HeaderBar from '@/components/Header'
import './plugins/api'
import VuetifyConfirm from 'vuetify-confirm'
import helpers from './helpers'
import 'vuetify/src/styles/main.sass'
import VueCookeis from 'vue-cookies'

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
Vue.use(VueCookeis);
require('./assets/scss/style.scss')
Vue.component(HeaderBar);
Vue.config.productionTip = false
Vue.mixin(helpers)
Vue.use(VuetifyConfirm, { vuetify })
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
