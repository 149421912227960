<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="pb-5">
            <v-toolbar color="primary" height="40" dark>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
            <v-card class="ma-5 pl-4">
                <v-simple-table
                    class="pa-1 pt-12 mr-4"
                    height="150"
                    fixed-header
                >
                    <col style="width: 150px" />
                    <col style="width: auto" />
                        <tr style="height: 70px;">
                            <th>メールアドレス</th>
                            <td>
                                <v-text-field
                                    solo
                                    maxlength="60"
                                    type="tel"
                                    :error="isError(err.mail)"
                                    outlined
                                    hide-details=""
                                    dense
                                    class="ma-2"
                                    v-model="inp.mail"
                                />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.mail)"
                                >
                                    <span v-html="dispError(err.mail)"></span>
                                </div>
                            </td>
                        </tr>
                        <tr style="height: 70px;">
                            <th>会員コード</th>
                            <td>
                                <v-text-field
                                    solo
                                    maxlength="10"
                                    type="tel"
                                    outlined
                                    :error="isError(err.memberCd)"
                                    hide-details="auto"
                                    dense
                                    class="ma-2 tokui-cd"
                                    v-model="inp.memberCd"
                                />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.memberCd)"
                                >
                                    <span v-html="dispError(err.memberCd)"></span>
                                </div>
                            </td>
                        </tr>
                </v-simple-table>
                <div class="text-center" style="height: 30px; color: red;">{{ regnefailed }}</div>
            </v-card>
            <v-card class="ma-5 text-right" elevation="0">
                <v-btn class="btn-edit ma-2" @click="clickGenerate()">パスワード再発行</v-btn>
                <v-btn class="btn-close ma-2" @click="dialog = false">閉じる</v-btn>
            </v-card>
        </v-card>
    </v-dialog>
</template>
<style scope>
.syuruiCd{
    width:100px;
}
.pass-forgot{
    color: blue;
    cursor: pointer;
}
</style>
<script>
import { CONSTANT } from '../../constant';
import { MESSAGE } from '../../messages'
export default {
    components: {  },
    data: () => ({
        dialog: false, // ダイアログ開閉
        title: 'パスワード再発行', // ダイアログタイトル
        showPass: false,
        regnefailed : '',

        // 入力v-model
        inp: ({} = {
            mail: '',
            memberCd: '',
        }),

        //エラーチェック用
        err: ({} = {
            mail: [],
            memberCd: [],
        }),
    }),
    props: {
        dlgCallback:{
            type: Function,
        }
    },
    methods: {
        // ダイアログ表示時の初期化
        async open(){
            this.dialog = true;
            this.clearinpData();
            this.clearError();
            this.regnefailed = '';
        },
        async clickGenerate(){

            this.regnefailed = '';
            this.clearError();

            //パスワード入力が空の場合、エラー表示
            if(this.inp.mail == ''){
                this.err.mail = [MESSAGE.formatByArr(MESSAGE.E001, 'メールアドレス')];
            }

            if(this.inp.memberCd == ''){
                this.err.memberCd = [MESSAGE.formatByArr(MESSAGE.E001, '会員ID')];
            }

            //エラーがある場合、アラート
            for(let key in this.err){
                if(this.err[key].length>0){
                    this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                    return;
                }
            }

           // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R004, 'パスワードを再発行')
            );

            if (!res) {
                return;
            }

            this.axios.post(`member/password-regene`, this.inp)
            .then((res) => {
                if(res.data.resultCode == CONSTANT.RETURN_NORMAL){
                    this.swalToast(MESSAGE.formatByArr(MESSAGE.I004,`メールを送信`));
                    this.dialog = false;
                }else{
                    this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                    this.err.mail = [' '];
                    this.err.memberCd = [' '];
                    this.regnefailed = 'メールアドレスまたは会員コードが違います。';
                }
            })

        },
        clearinpData(){
            for(let key in this.inp){
                this.inp[key] = [];
            }
        },
        clearError(){
            for(let key in this.err){
                this.err[key] = [];
            }
        },
    }
};
</script>