<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="pb-5">
            <v-toolbar color="primary" height="40" dark>
                <v-toolbar-title>最新情報更新</v-toolbar-title>
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-toolbar>
            <v-card class="ma-10">
                <v-simple-table class="plant-tbl">
                        <tr>
                            <th>原料土受入可能量<br/>（地山m3）</th>
                            <td>
                                <v-text-field
                                    v-model="gendoUke"
                                    type="text"
                                    maxlength="10"
                                    oninput="value = value.replace(/[^0-9]+/i,'');"
                                    solo
                                    dense
                                    outlined
                                    hide-details=""
                                    :error="isError(err.gendoUke)"
                                />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.gendoUke)"
                                >
                                    <span v-html="dispError(err.gendoUke)"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>改良土ストック量<br/>（ほぐしm3）</th>
                            <td>
                                <v-text-field
                                    v-model="kaidoStock"
                                    maxlength="100"
                                    type="text"
                                    oninput="value = value.replace(/[^0-9]+/i,'');"
                                    solo
                                    dense
                                    outlined
                                    hide-details=""
                                    :error="isError(err.kaidoStock)"
                                />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.kaidoStock)"
                                >
                                    <span v-html="dispError(err.kaidoStock)"></span>
                                </div>
                            </td>
                        </tr>
                    </v-simple-table>
            </v-card>
            <v-card class="my-5 mx-10 text-right" elevation="0">
                <v-btn class="mr-2 btn-save" @click="saveLately"> 保存 </v-btn>
                <v-btn class="btn-close" @click="dialog = false"> 閉じる </v-btn>
            </v-card>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.plant-tbl th {
    width: 200px;
}
</style>
<script>
import { MESSAGE } from "../../messages";
export default {
    components: { },
    props:{
        plantId: {
            type: Number,
            default: 0,
        },
        gendoUkeProp: {
            type: Number,
            default: 0,
        },
        kaidoStockProp: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        dialog: false,
        saveLoading: false,
        gendoUkePost: 0,
        kaidoStockPost: 0,
        err: {
            gendoUke: '',
            kaidoStock: '',
        }
    }),
    computed:{
       gendoUke: { 
           get() {
                return this.gendoUkeProp;
           },
           set(value) {
               this.gendoUkePost = Number(value);
           }
       },
       kaidoStock: {
           get() {
                return this.kaidoStockProp;
           },
           set(value) {
               this.kaidoStockPost = Number(value);
           }
       }
    },
    mounted() {
    },
    methods: {
       open() {
            this.dialog = true;
            this.gendoUkePost = this.gendoUkeProp;
            this.kaidoStockPost = this.kaidoStockProp;
       },
       async saveLately() {
            //error clear
            this.clearError();

            // input check
            if(await this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

           // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.R002);
            if(!res){
                this.saveLoading = null;
                return ;
            }

            const postData = {
                plant_id: this.plantId,
                now_gendo_uke: this.gendoUkePost,
                now_kaido_stock: this.kaidoStockPost,
            };

            this.axios.post("/member/plant-update-lately", postData)
            .then((res)=>{
                if(res.data.resultCode == 0){
                    // complete message
                    this.swalToast( MESSAGE.formatByArr(MESSAGE.I001,'最新情報'));
                    this.$emit('update-lately', { upddate: res.data.resultData.upddate, gendoUke: this.gendoUkePost, kaidoStock: this.kaidoStockPost });
                    this.dialog = false;
                }
            })
       },
       inputCheck() {

            // -- 原料土受入可能量
            if(this.gendoUke === ''){
                this.err.gendoUke = [MESSAGE.formatByArr(MESSAGE.E001,'原料土受入可能量')];
            }

            // -- 改良土ストック量
            if(this.kaidoStock === ''){
                this.err.kaidoStock = [MESSAGE.formatByArr(MESSAGE.E002,'改良土ストック量')];
            }

            for (let key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
       },
       /**
       * エラーの一括クリア
       */
       clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
       },
    },
};
</script>
